<template>
  <div class="add_con titlesTop">
    <headTitle title="添加我的证书" />
    <div class="add_fen">
      <div class="add_tit">证书分类：</div>
      <input
        type="text"
        placeholder="选择分类"
        v-model="classify"
        readonly
        @click="showPicker = true"
      />
    </div>
    <div class="add_img">
      <div class="add_tit">上传图片:</div>
      <div>
        <van-uploader
          v-model="fileList"
          accept="image/*"
          :max-count="1"
          :before-read="beforeRead"
          :after-read="afterRead"
        >
        </van-uploader>
      </div>
    </div>
    <div class="add_btm">
      <span>请确认证书信息，如有误请修改</span>
      <div class="add_btn" @click="submitBook">确定上传</div>
    </div>

    <!-- 证书分类 -->
    <van-action-sheet v-model="showPicker" :round="false" title="选择证书分类">
      <van-radio-group class="cls_wra" v-model="radio">
        <van-radio
          class="cls_itm"
          v-for="item in columns"
          :key="item.id"
          :name="item.certificateTypeName"
          @click="onSelect(item)"
          >{{ item.certificateTypeName }}</van-radio
        >
      </van-radio-group>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from "vant";
import PersonApi from "@/api/person";
import InteractionApi from "@/api/interaction";

export default {
  data() {
    return {
      fileList: [],
      showPicker: false,
      columns: [],
      classify: "",
      radio: "",
      fileUrl: "",
      bookClassId: "", // 证书分类ID
    };
  },
  mounted() {
    this.queryBookClass();
  },
  methods: {
    // 查询证书类型
    queryBookClass() {
      PersonApi.getBookClass({ appName: this.appName })
        .then((res) => {
          if (res.success) {
            this.columns = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 资源上传校验，图片每张不超过5M，视频不超过20M
    beforeRead(file) {
      const imgMaxSize = 8 * 1024 * 1024;
      var arr = [];
      arr = file.type.split("/");
      const fileType = arr[0];
      // 获取后缀名
      let index = file.name.lastIndexOf(".");
      file.fileSuffix = file.name.substr(index + 1);
      if (fileType !== "image") {
        Toast("请上传图片!");
        return false;
      }
      if (file.size > imgMaxSize) {
        Toast("图片大小不能超过8M");
        return false;
      }

      return true;
    },

    afterRead(file) {
      //   console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file);
      const params = {
        deviceNumber: "15665360907", // 设备号
        mediaSuffix: file.file.fileSuffix, // 文件后缀
      };
      InteractionApi.upLoadImg(formData, params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            this.fileUrl = res.data;
            file.status = "done";
            Toast("上传成功");
          }
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },

    onSelect(value) {
      this.classify = value.certificateTypeName;
      this.bookClassId = value.id;
      this.showPicker = false;
    },

    // 提交证书申请
    submitBook() {
      if (!this.classify) {
        Toast("请选择分类");
        return;
      }
      if (!this.fileUrl) {
        Toast("请上传证书图片");
        return;
      }
      const params = {
        certificatePath: this.fileUrl,
        certificateTypeId: this.bookClassId,
        appName: this.appName,
      };
      PersonApi.addBook(params)
        .then((res) => {
          if (res.success) {
            Toast("上传成功，等待管理员审核");
            this.classify = "";
            this.bookClassId = "";
            this.fileUrl = "";
            this.fileList = [];
            this.$router.back();
          } else {
            Toast(res.message);
          }
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.add_con {
  .add_fen {
    display: flex;
    padding: 10px;
    margin-top: 5px;
    // border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    input {
      border: none;
    }
  }
  .add_img {
    padding: 15px 10px;
    display: flex;
    border-bottom: 1px solid #ececec;
  }
  .add_btm {
    padding: 10px;
    margin-top: 50px;
    .add_btn {
      width: 100%;
      height: 45px;
      margin: 10px auto;
      line-height: 45px;
      color: #fff;
      background: #1377e2;
      text-align: center;
      border-radius: 5px;
    }
    span {
      font-size: 11px;
      color: #a5a5a5;
    }
  }
  .add_tit {
    margin-right: 15px;
  }
  .cls_wra {
    margin-left: 50px;
    height: 150px;
    overflow-y: auto;
    .cls_itm {
      padding: 5px 0;
    }
  }
}
</style>
